<template>
  <page-skeleton theme="light" :steps="false">
    <b-container>
      <h1 class="text-uppercase mt-5 my-3 title-text">
        {{ $t('terms.imprint.title') }}
      </h1>
      <b-row class="justify-content-center muted-text">
        <b-col cols="12" sm="6" md="4">
          <i18n path="terms.imprint.address.address" tag="div">
            <template v-slot:break>
              <div></div>
            </template>
            <template v-slot:bold>
            <span class="i-font-text-bold i-secondary">
              {{ $t('terms.imprint.address.bold') }}
            </span>
            </template>
          </i18n>
        </b-col>
        <b-col cols="12" sm="6" md="4">
          <div>Reg. Court Schweinfurt HRB 1693</div>
          <div>UStID DE133894376</div>
          <div>
            {{ $t('terms.imprint.commercialRegister.headquarters') }}: Berlin
          </div>
          <div>
            {{ $t('terms.imprint.commercialRegister.legalForm') }}: GmbH
          </div>
          <div>
            {{ $t('terms.imprint.management.ceo') }}: Gregor Fabritius
          </div>
        </b-col>
        <b-col cols="12" sm="6" md="4">
          <div>
            {{ $t('terms.imprint.contact.email') }}: mail@isotronic.de
          </div>
          <div>
            {{ $t('terms.imprint.contact.phone') }}: +49 (0) 9761-9281
          </div>
        </b-col>
      </b-row>

      <h1 class="text-uppercase mt-5 my-3 title-text">
        {{ $t('terms.privacy.title') }}
      </h1>
      <b-row class="mt-3 mb-5 justify-content-center muted-text">
        <b-col>
          <div>
            <i18n path="terms.privacy.section1" tag="div">
              <template v-slot:break>
                <div class="my-3"></div>
              </template>
            </i18n>
          </div>
          <div class="mt-3">
            {{ $t('terms.privacy.section2.text') }}
            <ol>
              <li>
                {{ $t('terms.privacy.section2.point1') }}
              </li>
              <li>
                {{ $t('terms.privacy.section2.point2') }}
              </li>
              <li>
                {{ $t('terms.privacy.section2.point3') }}
              </li>
            </ol>
          </div>
          <div class="mt-3">
            <h2 class="i-small i-primary text-uppercase">
              1. {{ $t('terms.privacy.section3.title') }}
            </h2>
            <div class="my-3">
              {{ $t('terms.privacy.section3.text1') }}
            </div>
            <div class="my-3">
              <i18n path="terms.privacy.section3.contact" tag="code" class="i-secondary">
                <template v-slot:break>
                  <div></div>
                </template>
                <template v-slot:dbreak>
                  <div class="my-3"></div>
                </template>
              </i18n>
            </div>
            <div class="my-3">
              {{ $t('terms.privacy.section3.text2') }}
            </div>
          </div>
          <div class="mt-3">
            <h2 class="i-small i-primary text-uppercase">
              2. {{ $t('terms.privacy.section4.title') }}
            </h2>
            <div class="my-3">
              {{ $t('terms.privacy.section4.points.title') }}
              <ul>
                <li>
                  {{ $t('terms.privacy.section4.points.point1') }}
                </li>
                <li>
                  {{ $t('terms.privacy.section4.points.point2') }}
                </li>
                <li>
                  {{ $t('terms.privacy.section4.points.point3') }}
                </li>
                <li>
                  {{ $t('terms.privacy.section4.points.point4') }}
                </li>
                <li>
                  {{ $t('terms.privacy.section4.points.point5') }}
                </li>
              </ul>
            </div>
            <div class="my-3">
              <i18n path="terms.privacy.section4.text" tag="div">
                <template v-slot:break>
                  <div class="my-3"></div>
                </template>
              </i18n>
            </div>
          </div>
          <div class="mt-3">
            <h2 class="i-small i-primary text-uppercase">
              3. {{ $t('terms.privacy.section5.title') }}
            </h2>
            <div class="my-3">
              {{ $t('terms.privacy.section5.text') }}
            </div>
            <div>
              <div class="my-3 i-sub-header">
                {{ $t('terms.privacy.section5.sub1.title') }}
              </div>
              <div class="my-3">
                <i18n path="terms.privacy.section5.sub1.text" tag="div">
                  <template v-slot:break>
                    <div class="my-3"></div>
                  </template>
                </i18n>
              </div>
            </div>
            <div>
              <div class="my-3 i-sub-header">
                {{ $t('terms.privacy.section5.sub2.title') }}
              </div>
              <div class="my-3">
                <i18n path="terms.privacy.section5.sub2.text" tag="div">
                  <template v-slot:break>
                    <div class="my-3"></div>
                  </template>
                </i18n>
              </div>
            </div>
            <div>
              <div class="my-3 i-sub-header">
                {{ $t('terms.privacy.section5.sub3.title') }}
              </div>
              <div class="my-3">
                <i18n path="terms.privacy.section5.sub3.text" tag="div">
                  <template v-slot:break>
                    <div class="my-3"></div>
                  </template>
                  <template v-slot:link1>
                    <b-link class="g-link"
                            href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
                      {{ $t('terms.privacy.section5.sub3.link1') }}
                    </b-link>
                  </template>
                  <template v-slot:link2>
                    <b-link class="g-link" href="https://www.google.com/intl/de/policies/privacy/partners">
                      {{ $t('terms.privacy.section5.sub3.link2') }}
                    </b-link>
                  </template>
                  <template v-slot:link3>
                    <b-link class="g-link" href="https://tools.google.com/dlpage/gaoptout">
                      {{ $t('terms.privacy.section5.sub3.link3') }}
                    </b-link>
                  </template>
                </i18n>
              </div>
            </div>
            <div>
              <div class="my-3 i-sub-header">
                {{ $t('terms.privacy.section5.sub4.title') }}
              </div>
              <div class="my-3">
                <i18n path="terms.privacy.section5.sub4.text" tag="div">
                  <template v-slot:break>
                    <div class="my-3"></div>
                  </template>
                </i18n>
              </div>
            </div>
          </div>
          <div class="mt-3" v-if="cookieStatus === 'accept' || cookieStatus === 'decline'">
            <h2 class="i-small i-primary">
              {{ $t('terms.privacy.section6.title') }}
            </h2>
            <b-row class="my-3">
              <b-col cols="auto" class="my-auto">
                {{ $t('terms.privacy.section6.text') }}
              </b-col>
              <b-col cols="auto">
                <b-button-group>
                  <b-button :variant="cookieStatus === 'accept' ? 'success' : 'light'"
                            @click="setCookieStatus('accept')"
                  >
                    {{ $t('terms.privacy.section6.accept') }}
                  </b-button>
                  <b-button :variant="cookieStatus === 'decline' ? 'success' : 'light'"
                            @click="setCookieStatus('decline')"
                  >
                    {{ $t('terms.privacy.section6.decline') }}
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import {mapMutations, mapState} from "vuex";

export default {
  components: {
    PageSkeleton
  },
  computed: {
    ...mapState({
      cookieStatus: state => state.cookieStatus
    }),
  },
  methods: {
    ...mapMutations([
      'SET_COOKIE_PERMISSION'
    ]),
    setCookieStatus(status) {
      localStorage.setItem("vue-cookie-accept-decline-cookePanel", status)
      this.SET_COOKIE_PERMISSION(status)
    }
  },
  metaInfo() {
    return {
      title: this.$t('terms.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('terms.meta.description')}
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.g-link
  color: $primary-color
  text-decoration: none

  &:hover
    text-decoration: underline

.title-text
  font-size: 60px

.muted-text
  color: #646568
</style>
